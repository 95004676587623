import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';

const AboutUsPage = () => {
  const [aboutUs, setAboutUs] = useState({ title: '', content: '' });
  const [error, setError] = useState('');

  useEffect(() => {
    const csrftoken = getCookie('csrftoken');
    axios.get('https://mandir-backend-10c4882b9e4e.herokuapp.com/api/about-us/', {
      headers: {
        'X-CSRFToken': csrftoken,
      },
      withCredentials: true,
    })
      .then(response => {
        if (response.data.length > 0) {
          setAboutUs(response.data[0]);
        }
      })
      .catch(error => {
        setError('There was an error fetching the About Us data!');
        console.error('There was an error fetching the About Us data!', error);
      });
  }, []);

  const getCookie = (name) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <Header />
      <main className="flex-grow p-8">
        <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6">
          <h1 className="text-4xl font-bold text-blue-600 mb-4">{aboutUs.title}</h1>
          <p className="mb-4">{aboutUs.content}</p>
          <div className="mt-8">
            <h2 className="text-2xl font-bold text-blue-600 mb-4">Mandir Locations</h2>
            <div className="mb-8">
              <h3 className="text-xl font-semibold text-gray-800">Shili Mandir</h3>
              <div className="w-full h-64">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7367.088293042786!2d73.13623711466789!3d22.5961466802702!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e36f4a1c1ac3d%3A0x28d38234d88c1021!2sSacchidanand%20Temple!5e0!3m2!1sen!2sus!4v1722398068233!5m2!1sen!2sus"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                  title="Shili Temple"
                ></iframe>
                <div className="text-center mt-2">
                  <a
                    href="https://www.google.com/maps/dir/?api=1&destination=22.5961466802702,73.13623711466789"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 underline"
                  >
                    Get Directions
                  </a>
                </div>
              </div>
            </div>
            <div className="mb-8">
              <h3 className="text-xl font-semibold text-gray-800">Rajupura Mandir</h3>
              <div className="w-full h-64">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1dNaN!2dNaN!3dNaN!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e2f26f0b4fb5d%3A0x6e2d7f8d3c5306a1!2sSadguru%20Mandir!5e0!3m2!1sen!2sus!4v1722397838131!5m2!1sen!2sus"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                  title="Rajupura Temple"
                ></iframe>
                <div className="text-center mt-2">
                  <a
                    href="https://www.google.com/maps/dir/?api=1&destination=21.643998599999998,72.88335359999999"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 underline"
                  >
                    Get Directions
                  </a>
                </div>
              </div>
            </div>
          </div>
          {error && <p className="text-red-500">{error}</p>}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default AboutUsPage;
