import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import Header from './Header';
import Footer from './Footer';
import heroImage from '../assets/hero-image.jpg'; // Ensure you have this image in the assets folder

const Homepage = () => {
  const [announcement, setAnnouncement] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    axios.get('https://mandir-backend-10c4882b9e4e.herokuapp.com/api/announcements/latest/')
      .then(response => {
        if (response.data.length > 0) {
          setAnnouncement(response.data[0]);
          setIsModalOpen(true);
        }
      })
      .catch(error => {
        console.error('There was an error fetching the latest announcement!', error);
      });
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <Header />
      <main className="flex-grow">
        <div className="relative bg-[#F6F4E5]"> {/* Set background color to match hero image */}
          <img src={heroImage} alt="Temple" className="w-full h-64 object-contain" />
        </div>
        <div className="p-8">
          <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6">
            <h1 className="text-4xl font-bold text-blue-600 mb-4">Welcome to Sadguru Mandir Web Site</h1>
            <p className="mb-4">
            પૂજ્ય શ્રી નારાયણદાસ મહારાજનું જીવન કઠિનાઈઓથી ભરેલું હતું. બાળપણમાં જ અનાથ થયેલા અને ગરીબ પરિવાર સાથે જોડાયેલા નારાયણદાસે મહાન સદભાવના બતાવી. તેમનો પરિવાર લગ્નના આમંત્રણ આપી નાનકડા દાનથી જીવન ચલાવતો. એક દિવસ, ડાકોર આમંત્રણ પહોચાડવાનું હતું, પણ નારાયણદાસની માતાએ કોઈ ઉપલબ્ધ ન હોવાનુ જણાવ્યુ. નારાયણદાસે જવા માટે આગ્રહ કર્યો અને ગરમીમાં અને પાદર વગરની પરિસ્થિતિમાં ચાલ્યા. તેમણે આમંત્રણ પહોચાડી અને ૧૧ રૂપિયા પુરસ્કાર મેળવ્યા, જે તેમણે માતાને આપ્યા. માતાએ તેમને આશીર્વાદ આપ્યા અને ઉજ્જવળ ભવિષ્યની આશા વ્યક્ત કરી.            </p>
            <p className="mb-4">
            જ્યારે નારાયણદાસ મહારાજ મોટા થયા, ત્યારે તેમણે વડોદરામાં મહારાજ સયાજીરાવ ગાયકવાડના મહેલમાં કામ કર્યું.  ત્યારે તેઓ બહુ ઓછું શિક્ષણ ધરાવતા હતા.  આ દરમિયાન, તેમણે પરમ પૂજ્ય નિર્ણય સ્વામી મહારાજ સાથે મુલાકાત કરી. નારાયણદાસે તેમની ખૂબ જ સેવા કરી. જયારે સ્વામી મહારાજ વિદાય લેવાના હતા, ત્યારે નારાયણદાસે તેમની સાથે જવાની ઇચ્છા વ્યક્ત કરી.   પણ ગુરુએ તેમને રહેવા માટે કહ્યું અને તેમની દૈવિક જ્ઞાનથી આશીર્વાદ આપવાની ખાતરી આપી. તેમણે નારાયણદાસને એક સંસ્કૃત પુસ્તક અને એક દીપકનો જ્યોત આપ્યો, જે તેમના નાભિથી ઉત્પન્ન કર્યો હતો. આ આશીર્વાદથી, નારાયણદાસે ટૂંક સમયમાં જ પુસ્તકમાંથી સંસ્કૃત શ્લોકો જાણકાર વિદ્વાનની જેમ વાંચી શક્યા.        </p>
            <p className="mb-4">
            નારાયણદાસ મહારાજ પોતાના ઘેર આ દીપકની જ્યોત લઈને પાછા આવ્યા અને શીલીમાં શ્રી સદગુરુ મંદિરમાં આ જ્યોત સ્થાપિત કરી. આ રીતે શ્રી સદગુરુ મંદિરની વારસતાની શરૂઆત થઈ.            </p>
            <p className="mb-4">
            શીલીમાં મંદિર સ્થાપના પછી, નારાયણદાસ મહારાજ વારંવાર વડોદરા જિલ્લામાં સાવલી તાલુકામાં આવેલ રાજુપુરા ગામમાં જતા હતા. રાજુપુરા તેમની પ્રિય જગ્યા હતી જ્યાં તેઓ સમય વિતાવતા અને ધ્યાન કરતા. પરમ પૂજ્ય નારાયણદાસ મહારાજ, પરમ પૂજ્ય શંકરલાલ મહારાજ અને પરમ પૂજ્ય હસમુખરાય મહારાજના આશીર્વાદથી, પરમ પૂજ્ય શ્રી શરદરાય મહારાજે ૨૪ જાન્યુઆરી, ૨૦૨૪ના રોજ રાજુપુરામાં એક અદ્ભુત મંદિરની સ્થાપના કરી.            </p>
            <p className="mb-4">
            શ્રી સદગુરુ મંદિર સમાજ અને આધ્યાત્મને નજીક લાવે છે. અમારા મંદિરમાં દરેકને આવકાર છે જે આધ્યાત્મિક વૃદ્ધિ અને શાંતિ શોધે છે. આ વેબસાઇટ દ્વારા, અમારા ઉદ્દેશ્ય છે કે દરેકને લાઇવ દર્શન, ઇવેન્ટ કેલેન્ડર, HD Pictures, અને બધી જ વસ્તુઓ પ્રદાન કરવી. આ વેબસાઇટ વિદેશમાં રહેતા લોકો અને જે મંદિરે હાજર રહી શકતા નથી તેમના માટે લાભકારી સાબિત થશે. આ વેબસાઇટ શ્રી સદગુરુ મંદિરને તમારા ફોન અને કમ્પ્યુટર્સ પર લાવશે.            </p>
          </div>
        </div>
      </main>
      <Footer />
      {announcement && (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          contentLabel="Announcement"
          className="modal"
          overlayClassName="overlay"
        >
          <h2>{announcement.title}</h2>
          <p>{announcement.message}</p>
          <button onClick={() => setIsModalOpen(false)}>Close</button>
        </Modal>
      )}
    </div>
  );
};

export default Homepage;
