import React from 'react';
import FlashAnnouncements from './FlashAnnouncements';

const Footer = () => {
  return (
    <footer className="bg-gray-200 py-4 mt-8">
      <FlashAnnouncements />
      <div className="container mx-auto text-center mt-4">
        <p className="text-sm text-gray-700">ગુરુ ગાદી, શીલી, તા. ઉમરેઠ, જી. આણંદ - ૩૮૮૨૧૦ | ગુરુ ગાદી, રાજુપુરા, તા. સાવલી,  જિ. વડોદરા - ૩૯૧૫૩૦  </p>
        <p className="text-sm text-gray-700 mt-2">© 2024 Developed by Ohm Shreem Company. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
