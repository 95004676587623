import React from 'react';
import Header from './Header';
import Footer from './Footer';
import upiQrCode from '../assets/upi-qr-code.png'; // Make sure to have the UPI QR code image in the assets folder

const DonationPage = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <Header />
      <main className="flex-grow p-8">
        <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6">
          <h1 className="text-4xl font-bold text-blue-600 mb-4">Donation</h1>
          <div className="mb-8">
            <h2 className="text-2xl font-semibold text-blue-500 mb-2">Bank Transfer Information</h2>
            <p className="mb-4">You can donate by transferring funds to the following bank account:</p>
            <ul className="list-disc list-inside mb-4">
              <li><strong>Bank Name:</strong> [Your Bank Name]</li>
              <li><strong>Account Number:</strong> [Your Account Number]</li>
              <li><strong>IFSC Code:</strong> [Your IFSC Code]</li>
              <li><strong>Account Holder Name:</strong> [Your Account Holder Name]</li>
            </ul>
            <p>Please mention "Donation" in the transaction remarks.</p>
          </div>
          <div className="mb-8">
            <h2 className="text-2xl font-semibold text-blue-500 mb-2">UPI Payment</h2>
            <p className="mb-4">Scan the QR code below to donate via UPI:</p>
            <img src={upiQrCode} alt="UPI QR Code" className="w-64 h-64 object-cover rounded-lg mb-4" />
            <p className="mb-4">Alternatively, you can use the UPI ID: <strong>[Your UPI ID]</strong></p>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default DonationPage;
