import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';

const LiveDarshanPage = () => {
  const [darshan, setDarshan] = useState([]);
  const [currentLive, setCurrentLive] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    axios.get('https://mandir-backend-10c4882b9e4e.herokuapp.com/api/live-darshan/')
      .then(response => {
        setDarshan(response.data);
        const live = response.data.find(d => d.is_live);
        setCurrentLive(live);
      })
      .catch(error => {
        console.error('There was an error fetching the live darshan!', error);
        if (error.response && error.response.status === 403) {
          setErrorMessage("Due to a high number of views, the live stream is unavailable here. Please watch directly on YouTube.");
          setCurrentLive({ stream_url: 'https://www.youtube.com/channel/UCMDiTFFfeHcwW0Fs79KYAEw/live' }); // Use the direct live stream URL
        }
      });
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <Header />
      <main className="flex-grow p-8">
        <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6">
          <h1 className="text-4xl font-bold text-blue-600 mb-4">Live Darshan</h1>
          {errorMessage && (
            <div className="mb-8">
              <h2 className="text-2xl font-semibold text-red-500 mb-2">Notice</h2>
              <p className="text-red-500">{errorMessage}</p>
            </div>
          )}
          {currentLive && (
            <div className="mb-8">
              <h2 className="text-2xl font-semibold text-blue-500 mb-2">Current Live Stream</h2>
              <iframe
                src={currentLive.stream_url}
                title="Live Darshan"
                className="w-full h-64 rounded-lg"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          )}
          <div className="mb-8">
            <h2 className="text-2xl font-semibold text-blue-500 mb-2">Schedule</h2>
            <ul>
              {darshan.map(item => (
                <li key={item.id || item.title} className="mb-4">
                  <strong>{item.title}</strong> - {new Date(item.schedule).toLocaleString()}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default LiveDarshanPage;
