import React, { useState } from 'react';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-white shadow-md py-4">
      <div className="container mx-auto flex items-center justify-between">
        <div className="flex-shrink-0">
          <a href="/">
            <img src="/temple-icon.png" alt="Temple Icon" className="h-20 w-20" /> {/* Updated size */}
          </a>
        </div>
        <div className="flex-grow text-center">
          <h1 className="text-3xl font-bold text-red-600">શ્રી સદગુરૂ મંદિર</h1>
          <div className="text-blue-600 font-bold">
            <p>।। ॐ ।।</p> 
            <p>।। જય સચ્ચિદાનંદ ।।</p>
            <p>।। શ્રી નારાયણ ગુરૂવે નમઃ ।।</p>
          </div>
        </div>
        <div className="flex-shrink-0">
          <a href="/">
            <img src="/bhakti.png" alt="Bhakti Icon" className="h-20 w-20" /> {/* New Bhakti icon */}
          </a>
          <button
            onClick={toggleMenu}
            className="text-blue-600 focus:outline-none sm:hidden"
          >
            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}></path>
            </svg>
          </button>
        </div>
      </div>
      <div className="container mx-auto flex justify-center space-x-4 mt-4">
        <a href="https://www.instagram.com/sadgurumandir/profilecard/?igsh=ZnF5eHF0NGJvbDl5" target="_blank" rel="noopener noreferrer">
          <img src="/ig-icon.png" alt="Instagram" className="h-8 w-8" /> {/* Add the path to your Instagram icon */}
        </a>
        <a href="https://www.facebook.com/profile.php?id=61550556568521" target="_blank" rel="noopener noreferrer">
          <img src="/fb-icon.png" alt="Facebook" className="h-8 w-8" /> {/* Add the path to your Facebook icon */}
        </a>
        <a href="https://chat.whatsapp.com/Fw3rjv6TKjMAiQZQTOZwlM">
          <img src="/whatsapp-icon.png" alt="whatsapp" className="h-8 w-8" /> {/* Add the path to your whats icon */}
        </a>
        <a href="#" target="_blank" rel="noopener noreferrer">
          <img src="/youtube-icon.png" alt="YouTube" className="h-8 w-8" /> {/* Add the path to your YouTube icon */}
        </a>
        <a href="https://x.com/SadguruMandir" target="_blank" rel="noopener noreferrer">
          <img src="/x-icon.png" alt="X" className="h-8 w-8" /> {/* Add the path to your X (Twitter) icon */}
        </a>
        <a href="mailto:management@sadgurumandir.org">
          <img src="/email-icon.png" alt="Email" className="h-8 w-8" /> {/* Add the path to your Email icon */}
        </a>
      </div>
      <nav className={`bg-gray-100 mt-4 ${isOpen ? 'block' : 'hidden'} sm:block`}>
        <div className="container mx-auto flex flex-col sm:flex-row justify-center space-y-2 sm:space-y-0 sm:space-x-4 py-2">
          <a href="/" className="text-blue-500 hover:text-blue-700">Home</a>
          <a href="/events" className="text-blue-500 hover:text-blue-700">Events</a>
          <a href="/media" className="text-blue-500 hover:text-blue-700">Media</a>
          <a href="/live-darshan" className="text-blue-500 hover:text-blue-700">Live Darshan</a>
          <a href="/donations" className="text-blue-500 hover:text-blue-700">Donations</a>
          <a href="/about-us" className="text-blue-500 hover:text-blue-700">About Us</a>
          <a href="/contact-us" className="text-blue-500 hover:text-blue-700">Contact Us</a>
        </div>
      </nav>
    </header>
  );
};

export default Header;
