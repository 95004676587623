import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.css'; // Ensure this import path is correct
import Header from './Header';
import Footer from './Footer';

const localizer = momentLocalizer(moment);

const EventsPage = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    axios.get('https://mandir-backend-10c4882b9e4e.herokuapp.com/api/events/')
      .then(response => {
        const formattedEvents = response.data.map(event => ({
          title: event.title,
          start: new Date(event.date),
          end: new Date(event.date),
          allDay: true,
          mandirSpecific: event.mandir_specific,
        }));
        setEvents(formattedEvents);
      })
      .catch(error => {
        console.error('There was an error fetching the events!', error);
      });
  }, []);

  const eventPropGetter = (event) => {
    let className = '';
    if (event.mandirSpecific) {
      className = 'red-background';
    } else {
      className = 'small-font';
    }
    return {
      className,
    };
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <Header />
      <main className="flex-grow p-8">
        <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6">
          <h1 className="text-4xl font-bold text-blue-600 mb-4">Events</h1>
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
            eventPropGetter={eventPropGetter}
          />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default EventsPage;
