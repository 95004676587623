import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css"; // Import the gallery styles

const MediaPage = () => {
  const [media, setMedia] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const csrftoken = getCookie('csrftoken');
    axios.get('https://mandir-backend-10c4882b9e4e.herokuapp.com/api/media/', {
      headers: {
        'X-CSRFToken': csrftoken,
      },
      withCredentials: true,
    })
      .then(response => {
        // Transform media data to fit react-image-gallery format
        const galleryItems = response.data.map(item => ({
          original: item.file,
          thumbnail: item.file,
          description: item.title,
        }));
        setMedia(galleryItems);
      })
      .catch(error => {
        setError('There was an error fetching the media!');
        console.error('There was an error fetching the media!', error);
      });
  }, []);

  const getCookie = (name) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <Header />
      <main className="flex-grow p-8">
        <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6">
          <h1 className="text-4xl font-bold text-blue-600 mb-4">Media</h1>
          {media.length > 0 && (
            <ImageGallery 
              items={media}
              showThumbnails={true} // Display thumbnails below the main image
              showPlayButton={false} // Hide the play button
              showFullscreenButton={true} // Allow full-screen viewing
              useBrowserFullscreen={true} // Use browser's fullscreen API
            />
          )}
          {error && <p className="text-red-500">{error}</p>}
        </div>
        <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6 mt-8">
          <h2 className="text-3xl font-bold text-blue-600 mb-4">Latest Posts</h2>
          <div className="mb-8">
            <h3 className="text-2xl font-semibold mb-4">Facebook</h3>
            <div className="fb-page" data-href="https://www.facebook.com/profile.php?id=61550556568521" data-tabs="timeline" data-width="500" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true">
              <blockquote cite="https://www.facebook.com/profile.php?id=61550556568521" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/profile.php?id=61550556568521">Facebook Page</a></blockquote>
            </div>
          </div>
          <div className="mb-8">
            <h3 className="text-2xl font-semibold mb-4">Instagram</h3>
            <blockquote className="instagram-media" data-instgrm-permalink="https://www.instagram.com/p/CJ6t8eJnnQ8/" data-instgrm-version="12"></blockquote>
            <script async src="//www.instagram.com/embed.js"></script>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default MediaPage;
