// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.small-font {
  font-size: 0.8em;
  vertical-align: bottom;
  color: black; /* Ensure the text is visible */
}

.red-background {
  background-color: red !important;
  color: white !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Calendar.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,sBAAsB;EACtB,YAAY,EAAE,+BAA+B;AAC/C;;AAEA;EACE,gCAAgC;EAChC,uBAAuB;AACzB","sourcesContent":[".small-font {\n  font-size: 0.8em;\n  vertical-align: bottom;\n  color: black; /* Ensure the text is visible */\n}\n\n.red-background {\n  background-color: red !important;\n  color: white !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
