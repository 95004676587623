import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../index.css'; // Ensure this points to your CSS file

const FlashAnnouncements = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const csrftoken = getCookie('csrftoken');
    axios.get('https://mandir-backend-10c4882b9e4e.herokuapp.com/api/flash-announcements/', {
      headers: {
        'X-CSRFToken': csrftoken,
      },
      withCredentials: true,
    })
      .then(response => {
        setAnnouncements(response.data.filter(announcement => announcement.is_active));
      })
      .catch(error => {
        setError('There was an error fetching the flash announcements!');
        console.error('There was an error fetching the flash announcements!', error);
      });
  }, []);

  const getCookie = (name) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  };

  return (
    <div className="bg-[#F6F4E5] p-4 opacity-75">
      <div className="marquee">
        <div className="marquee-content">
          {announcements.map((announcement, index) => (
            <span key={index} className="mx-4">
              {announcement.message}
            </span>
          ))}
        </div>
      </div>
      {error && <p className="text-red-500">{error}</p>}
    </div>
  );
};

export default FlashAnnouncements;
